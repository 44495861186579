.heading-container{
    width: 100%;
    background-color: var(--primary-text);
    height: 170px;
    display: flex;
    border-bottom: 1px solid rgb(224, 224, 224);
    font-size: 40px;
   
    font-family: 'Cinzel', serif;
    
    border-top: 1px solid rgb(224, 224, 224);
    
}
.meet-container{
    background-color: var(--light-bg);
    width: 100%;
}
.aboutus-container{
    width: 100%;
    height: auto;
    
}
.meet-font{
    color: var(--primary-text);
    font-family: 'IBM Plex Serif', serif;
}

.about-h1{
    font-size: 30px;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 600;
    color: var(--primary-text);
    
}
@media only screen and (max-width:1400px) {
    .about-content p{
        padding: 0 30px;
    }
}

@media only screen and (max-width:1210px) {
    .about-content p{
        padding: 0 40px;
    }
}
@media only screen and (max-width: 520px){
    .heading-container{
        font-size: 25px;
        height: 130px;
    }
}
@media only screen and (max-width:319px)  { body{font-size:11px}}
@media only screen and (min-width:320px) and (max-width:767px)  { body{font-size:12px} }
@media only screen and (min-width:768px) and (max-width:1023px)  { body{font-size:14px} }
@media only screen and (min-width:1024px) and (max-width:1899px) { body{font-size:16px} }
@media only screen and (min-width:1900px) { body{font-size:18px} }

.others-container{
    width: 100%;
    background: var(--light-bg);
    height: 170px;
    display: flex;
    border-bottom: 1px solid rgb(224, 224, 224);
    font-size: 40px;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 600;
    color: var(--primary-text);
    border-top: 1px solid rgb(224, 224, 224);
}
.Faq-h2{
    font-family: 'IBM Plex Serif', serif;
    font-weight: 500;
}
.heading-container{
    width: 100%;
    background-color: var(--light-bg);
    height: 170px;
    display: flex;
    font-family: 'IBM Plex Serif', serif;
    border-bottom: 1px solid rgb(224, 224, 224);
    font-size: 40px;
    color: var(--primary-text);
    font-weight: 700;
   
}
.contact-container{
  width: 100%;

}
.video-container{
  width: 100%;
  height: auto;
  margin-bottom: 150px;
}

.map iframe{
  width: 100%;
}
/* .video-container{
width: auto;
} */
@media only screen and (max-width: 600px){
    .heading-container{
        font-size: 25px;
        height: 130px;
    }
    .video-container{
        width: 100%;
        margin-bottom: 100px;
        }
       
        
      }






@media only screen and (max-width:319px)  { body{font-size:11px}}
@media only screen and (min-width:320px) and (max-width:767px)  { body{font-size:12px} }
@media only screen and (min-width:768px) and (max-width:1023px)  { body{font-size:14px} }
@media only screen and (min-width:1024px) and (max-width:1899px) { body{font-size:16px} }
@media only screen and (min-width:1900px) { body{font-size:18px} }
.heading-container{
    width: 100%;
    background-color: var(--light-bg);
    height: 170px;
    display: flex;
    border-bottom: 1px solid rgb(224, 224, 224);
    font-size: 40px;
    font-weight: 500;
    border-top: 1px solid rgb(224, 224, 224);
    
}
@media only screen and (max-width: 520px){
    .heading-container{
        font-size: 25px;
        height: 130px;
    }
}
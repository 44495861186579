.home-container {
  width: 100%;
}

/* .image-slider {
  width: 100%;
  height: 600px;
  background-image: url("https://images.unsplash.com/photo-1668416876357-166f2f36a529?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bGFuc2NhcGUlMjB3YWxscGFwZXJ8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60");
  background-size: 100% 100%;
  animation: changeImage 20s ease-in-out infinite;
} */
.carousel-item {
  transition: transform 1.6s ease-in-out;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  transition: opacity 0s 2.6s;
}
.home-about {
  width: auto;
  height: 530px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.about-home {
  width: 100%;
  height: 400px;
  background-color: var(--light-bg);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.about-home img {
  margin-left: 200px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.about-home-content {
  display: block;
  /* border: 1px solid black;  */
  margin-left: 100px;
  margin-right: 200px;
  height: 290px;
}
.about-home-content h1 {
  font-size: 2em;
  color: var(--primary-text);
  font-weight: 600;
  margin-bottom: 20px;
  text-align: right;
}
.home-button {
  background-color: var(--primary-text);
  float: right;
}
.about-home-content p {
  text-align: justify;
  margin-bottom: 30px;
}

.home-service {
  width: 100%;
  height: 400px;
}
.home-service h1 {
  font-size: 30px;
  padding-top: 40px;
  color: var(--primary-text);
  font-weight: 600;
}
.home-img-cards {
  margin-top: 30px;
  margin-left: 200px;
  margin-right: 200px;
  /* border: 1px solid black; */
  height: 200px;
  display: flex;
  flex-direction: row;
}
.home-img-cards img {
  height: 200px;
  width: 200px;
  margin-right: 105px;
}
.home-button2 {
  background-color: var(--primary-text);
  margin-top: 30px;
}
.home-tagline {
  width: 100%;
  height: 300px;
  display: block;
  justify-content: center;
  align-items: center;
}
.home-tagline h1 {
  color: var(--primary-text);
  font-size: 30px;
  font-weight: 600;
  padding-top: 40px;
}
.home-tagline p {
  justify-content: center;
  /* border: 1px solid black; */
  margin-left: 500px;
  margin-right: 500px;
  margin-top: 20px;
  text-align: center;
}
.home-button1 {
  background-color: var(--primary-text);
  display: flex;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.appoinment-call-btn:hover {
  color: var(--light-bg);
}

@media only screen and (max-width: 1440px) {
  .home-about {
    width: 100%;
    height: auto;
  }
}
/* @media only screen and (max-width:520px){
  .image-slider {
    width: 100%;
    height: 400px;
    background-image: url("https://images.unsplash.com/photo-1668416876357-166f2f36a529?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bGFuc2NhcGUlMjB3YWxscGFwZXJ8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60");
    background-size: 100% 100% ;
    animation: changeImage 20s ease-in-out infinite;
  }
}
@keyframes changeImage {
  0% {
    background-image: url("https://media.istockphoto.com/id/1220728836/photo/tropical-island-palm-tree-beach-from-above.webp?b=1&s=170667a&w=0&k=20&c=T69xsJRRRo7n-7hhtKlOjg7fuywoMULElOR8_7xSg34=");
  }
  25% {
    background-image: url("https://media.istockphoto.com/id/1173544006/photo/winding-road.webp?b=1&s=170667a&w=0&k=20&c=nGsAlwPP_MifoVapyWVeJjC0uy7bMhgDN9CXsVWdRGg=");
  }
  50% {
    background-image: url("https://media.istockphoto.com/id/1197996070/photo/lavender-field-in-summer.webp?b=1&s=170667a&w=0&k=20&c=h_0ZIH-DetL8h0tar1h6QsMaEamXdYuqE7wLImrh7hg=");
  }
  75% {
    background-image: url("https://media.istockphoto.com/id/841278554/photo/beautiful-morning-light-in-public-park-with-green-grass-field.webp?b=1&s=170667a&w=0&k=20&c=S9wt-9ng2GIWMUKhZFU0P3Zb2GxZ4cAkZz_g0wu1N8M=");
  }
  100% {
    background-image: url("https://media.istockphoto.com/id/1091165998/photo/city-skyline-and-buildings-with-empty-asphalt-road-at-sunrise.webp?b=1&s=170667a&w=0&k=20&c=K8jAR4xVOkfXGqRbf5dcQKdRL4h40W6LCERbqAgrj8E=");
  }
} */
@media only screen and (max-width: 319px) {
  body {
    font-size: 11px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  body {
    font-size: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  body {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1899px) {
  body {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1900px) {
  body {
    font-size: 18px;
  }
}

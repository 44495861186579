@keyframes slide{
    from{
        transform:translateX(0);
    }
    to{
        transform:translateX(-100%);
    }
}

.brand-logo-container{
    width: 100%;
    background: var(--light-bg);
    padding-top: 5px;
}
.brand-heading{
    font-family: 'IBM Plex Serif', serif;
    font-weight: 600;
    margin-top: 30px;
    font-size: 35px;
    color: var(--primary-text);
}
.brand-logos{
    width: 100%;
    overflow: hidden;  
    padding: 60px 0px;
    white-space: nowrap;
    position: relative;
    animation-play-state: running;
 }
.brand-logos:before,
.brand-logos:after{
    position: absolute;
    top: 0;
    width: 350px;
    height: 100%;
    content: "";
    z-index: 2;
}
.brand-logos:before{
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0),var(--light-bg));
    
}
.brand-logos:after{
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--light-bg));
   
}

.brand-logos:hover .brand-logos-slide{
    animation-play-state: paused;
}

.brand-logos-slide{
    display: inline-block;
    animation: 40s slide infinite linear;
   
   
    

}
.brand-logos-slide img{
    height: 150px;
    width: 200px;
    justify-content: center;
    align-items: center;
    image-resolution: from-image 300dpi;
    object-fit: contain;
    border: 1px solid rgba(17, 17, 17, 0.99); 
    
  
    margin:0 30px;
    display: inline-flex;
 
}
.appointment-div{
    width: 100%;
    margin-top: 40px;
}
.appointment-icon {
    color: rgb(201, 201, 201);
    text-align: center;
    justify-content: center;
    align-items: center;
}
.appoinment-text{
    font-size: 30px;
    margin-top: 20px;
    font-family: 'IBM Plex Serif', serif;
    margin-bottom: 40px;
    text-align: center;
    
}
.appoinment-button{
    margin-bottom: 70px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.appoinment-button button{
    text-align: center;
    align-items: center;
    border-radius: 50px;
    justify-content: center;
    color: white;
    background-color: var(--primary-text);
}
/* .appoinment-button button:hover{
    background: black;
} */
.background-card{
    background: rgba(255, 255, 255, 0.601);
    color: black;
   
}

.consultancy-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.rigth-section{
    width: 50%;
    text-align: justify;
}
.rigth-section h2{
    
   margin-bottom: 30px;
   margin-top: 50px;
    text-align: left; 
}
.right-icons{
    display:flex;
    align-items: center;
    justify-content: space-between;
    
}
.tick-icon{
    color: var(--primary-text);
}
.right-icons span{
    margin-left: 7px;
    text-align:start;
    float: right;

}
.left-section{
    width: 50%;
    
   
   
}
.left-img{
    height: 100%;
    width: auto;
    float: right;
}

@media only screen and (max-width:1210px) {
    .consultancy-container{
        display: inline-block;
        /* flex-direction: column; */
        width: auto;
    }
    .rigth-section{
        width: 100%;
        padding: 0 30px;
       
    }
    .left-img{
        vertical-align: middle;
        height: auto;
        width: auto;
        
    }
    .left-section{
        height: auto;
        width: auto;
      
    }
    .brand-logos:before,
.brand-logos:after{
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
    content: "";
    z-index: 2;
}
}

@media only screen and (max-width:900px) {
    .left-img{
        height: auto;
        width: auto;
        justify-content: center;
        display:block;margin:0 auto;
      }
      .left-section{
        height: auto;
        width: auto;
        margin-top: 20px;
    }
    .consultancy-container{
        display: flex;
        flex-direction: column;
    }
    .rigth-section{
        padding: 0 40px;
    }
    .brand-logos:before,
.brand-logos:after{
    position: absolute;
    top: 0;
    width: 100x;
    height: 100%;
    content: "";
    z-index: 2;
}
    
}

@media only screen and (max-width:600px) {
    .consultancy-container{
        display: flex;
        flex-direction: column;
        height: auto;
        width: auto;
    }
    .left-section{
        height: auto;
        width: auto;
        margin-top: 20px;
    }
    .brand-logos:before,
.brand-logos:after{
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    content: "";
    z-index: 2;
}
    
    .brand-heading{
        font-size: 30px;
    }
    
}
@media only screen and (max-width:319px)  { body{font-size:11px}}
@media only screen and (min-width:320px) and (max-width:767px)  { body{font-size:12px} }
@media only screen and (min-width:768px) and (max-width:1023px)  { body{font-size:14px} }
@media only screen and (min-width:1024px) and (max-width:1899px) { body{font-size:16px} }
@media only screen and (min-width:1900px) { body{font-size:18px} }

.vip-container{
    width: 100%;
}

.vip-header{
    width: 100%;
    background-image: url(../../images/Kama_cover.jpeg);
    height: 450px;
    display: flex;
    background-size: cover;
    font-size: 50px;
    font-weight: 600;
    font-family: 'IBM Plex Serif', serif;
    color: rgb(255, 255, 255); 
    background-position: center top;
}
@media only screen and (max-width: 520px){
    .vip-header{
        font-size: 25px;
        height: 130px;
    }
}
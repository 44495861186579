.max-width{
    max-width: 1300px;
    margin: 50px auto;
}

.absolute-centre{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cur-po{
    cursor: pointer;
}

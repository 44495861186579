.services-container{
    width: 100%;
}
.services-header{
    width: 100%;
    background-image: url(../../images/Kama_cover.jpeg);
    height: 450px;
    display: flex;
    background-size: cover;
    font-size: 60px;
    font-weight: 600;
    font-family: 'IBM Plex Serif', serif;
    color: rgb(255, 255, 255); 
    background-position: center top;
}
.text-services{
  justify-content: space-around;
  display: flex;
  align-items: center;
}
.button-servies{
  display: flex;
  justify-content: space-around;
}
.button-servies button{
    display: flex;
    
}

@media only screen and (max-width: 520px){
    .services-header{
        font-size: 25px;
        height: 130px;
    }
    .button-servies{
        display: block;
    margin-left: 30px;
        
    }
    .button-servies button{
        display: block;
        margin-bottom: 30px;

    }
}
@media only screen and (max-width: 319px) {
    body {
      font-size: 11px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    body {
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    body {
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1899px) {
    body {
      font-size: 16px;
    }
  }
  @media only screen and (min-width: 1900px) {
    body {
      font-size: 18px;
    }
  }

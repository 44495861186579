.footer-container {
  display: flex;
  justify-content: space-between;
  background-color: black;
  color: white;
  text-align: center;
}

.text-footer:hover {
  color: var(--primary-text);
}
.logofoot {
  max-width: 100%;
  height: 200px;
  width: auto;
  display: flex;
}
@media only screen and (max-width: 1024px) {
  .img-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-address{
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   text-align: center;
  }
  .footer-icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

}
@media only screen and (max-width: 768px) {
  .img-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-address{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  .logofoot{
    height: 250px;
  width: auto;
  display: flex;
  }

}
@media only screen and (max-width: 425px) {
  .img-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-address{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .logofoot{
    height: 300px;
  width: auto;
  display: flex;
  }
}

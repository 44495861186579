* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
.header-container{
  width: 100%;
  height: 90px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 0 50px;
  position: relative;
  position: sticky; top: 0;
  z-index:999;
  font-weight: 400;
  border-bottom: 1.5px solid rgba(224, 224, 224, 0.537);
  font-family: 'IBM Plex Serif', serif;
}

.header-container:hover{
  background: white;
}
.logo{
  margin-left: 10px;
  height: 70px;
  width:auto;
}

.logo img{
  height:70px;
  width: auto;
}
.hamburger-menu{
  display: none;
}
.menu-link{
  margin-left: 15px;
  position: relative;
 
}
.menu-link ul li {
  position: relative;
}
.nav-link:hover, .nav-link:focus{
  color: var(--primary-text);
}
.nav-link::after{
  content: "";
  height: 2px;
  width: 100%;
  background: black;
  position: absolute;
  left: 0;bottom: -22.6px;
  opacity: 0;
  transition: all 0.2s;
  pointer-events: none;

}
.nav-link:hover::after,.nav-link:focus::after{
  opacity: 1;


}
.icons:hover{
  color: var(--primary-text);
}

.menu-link ul{
  display: flex;
  text-decoration: none;
 
}
.menu-link ul li a{
  display: block;
  font-size: 17px;
  text-decoration: none;
  color: black;
  padding: 10px 20px;
  margin: 0 5px;
}



.header-icons{
  display: flex;
}



@media only screen and (max-width:1320px) {
  .header-container{
    padding: 0 50px;
  }
  .icons{
    margin: 0px 15px;
    size: 0.5em;
  }
  
}
@media only screen and (max-width:1210px) {
  .header-container{
    padding: 0 30px;
  }
  .icons{
    display: none;
  }
  .logo img{
    height: 70px;
    width: auto;
  }
  .hamburger-menu{
    display: block;
    cursor: pointer;
    font-size: 30px;
  }
  .menu-link{
    position: absolute;
    height: 0;
    top: 90px;
    margin-left: 0px;
    left:0;
    right: 0;
    width: 100vw;
    background: var(--light-bg);
    transition: 0.5s;
    overflow: hidden;
  }
  .menu-link.active{
    height: 100vh;

  }
  .menu-link ul{
    display: block;
    width: fit-content;
    margin: 15px auto 0 auto;
    text-align: centre;
    transition: 0.5s;
    opacity: 0;
  }
  .menu-link ul li a{
    margin-bottom: 50px;
  }
  .menu-link.active ul{
    opacity: 1;
  } 
  
  
}
@media only screen and (max-width:319px)  { body{font-size:11px}}
@media only screen and (min-width:320px) and (max-width:767px)  { body{font-size:12px} }
@media only screen and (min-width:768px) and (max-width:1023px)  { body{font-size:14px} }
@media only screen and (min-width:1024px) and (max-width:1899px) { body{font-size:16px} }
@media only screen and (min-width:1900px) { body{font-size:18px} }